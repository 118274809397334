textarea {
    overflow: hidden;
    resize: none;
    /*color: black;*/
    color: rgb(32, 119, 191);
    font-size: 1rem;
    letter-spacing: 1px;
    line-height: 30px;
    text-align: center;
    padding-top: 15px;
    align-items: center;
    justify-content: center;
    /*border: 2px solid rgba(32, 119, 191, 0.4);*/
    border: 2px solid rgba(78, 173, 142, 0.4);
    /*background-color: 'rgba(32, 119, 191, 1);',
    background-color: #161616;*/
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

textarea:focus {
    /*border: 2px solid rgb(32, 119, 191);*/
    border: 2px solid #4EAD8E;
    outline: 0;
}

textarea::placeholder {
    /*color: rgb(32, 119, 191);*/
    color: #4EAD8E;
}